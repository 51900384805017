import { useOktaAuth } from '@okta/okta-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { memo, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { queryClient } from 'src/api/hooks/service';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import Messages from 'src/components/Messages/Messages';
import { MessagesProvider } from 'src/components/Messages/messages-context';
import { MapsProvider } from 'src/contexts/maps-context';
import { ProfileSettingsProvider } from 'src/contexts/profile-settings-context';
import { PushNotificationsProvider } from 'src/contexts/push-notifications-context';
import { UrlSettingsProvider } from 'src/contexts/url-settings-context';
import { lazyWithRetry } from 'src/tools/lazy-with-retry';
import Analytics from './Analytics';
import './Root.scss';

const Authenticated = lazyWithRetry(() => import('./Authenticated'), {
  throwAfterAttempts: true,
});
const Unauthenticated = lazyWithRetry(() => import('./Unauthenticated'), {
  throwAfterAttempts: true,
});

function Root() {
  const { authState } = useOktaAuth();

  return (
    <BrowserRouter>
      <UrlSettingsProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />

          <Analytics>
            <MessagesProvider>
              <Messages />
              <Suspense
                fallback={
                  <div className="command-center-root__loader">
                    <FullWidthLoader />
                  </div>
                }
              >
                {authState?.isAuthenticated ? (
                  <MapsProvider>
                    <ProfileSettingsProvider>
                      <PushNotificationsProvider>
                        <Authenticated />
                      </PushNotificationsProvider>
                    </ProfileSettingsProvider>
                  </MapsProvider>
                ) : (
                  <Unauthenticated />
                )}
              </Suspense>
            </MessagesProvider>
          </Analytics>
        </QueryClientProvider>
      </UrlSettingsProvider>
    </BrowserRouter>
  );
}

export default memo(Root);
